<template>
  <div>
    <CToaster position="top-right" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast
            :key="'toast' + toast.text + index"
            :show="true"
            :header="toast.header === '' ? null : toast.header"
        >
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CRow>
      <CCol class="col-12 col-md-6">
        <CCard>
          <CCardHeader>
            <CRow class="align-items-center justify-content-center">
              <CCol class="col-4">
                <strong>Öğrenci Detayları</strong>
              </CCol>
              <CCol class="col-8 d-flex flex-wrap align-items-center justify-content-end">
                <CBadge v-if="isBanned" color="danger" class="mr-1">
                  {{ isBanned ? 'Banlanmış' : ' Banlı Değil' }}
                </CBadge>
                <CBadge v-else color="success" class="mr-1 ml-1">
                  {{ isBanned ? 'Banlanmış' : ' Banlı Değil' }}
                </CBadge>
                <CBadge v-if="isPremium" color="success" class="mr-1 ml-1">
                  {{ isPremium ? 'Premium' : ' Premium Değil' }}
                </CBadge>
                <CBadge v-else color="danger" class="mr-1 ml-1">
                  {{ isPremium ? 'Premium' : ' Premium Değil' }}
                </CBadge>
                <CBadge v-if="isStudentAvatarApproved" color="success" class="ml-1">
                  {{ isStudentAvatarApproved ? 'Avatarı Onaylandı' : ' Avatarı Onaylanmadı' }}
                </CBadge>
                <CBadge v-else color="danger" class="ml-1">
                  {{ isStudentAvatarApproved ? 'Avatarı Onaylandı' : ' Avatarı Onaylanmadı' }}
                </CBadge>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol class="text-center">
                <strong>Profil Fotoğrafı</strong>
              </CCol>
            </CRow>
            <div class="d-flex justify-content-center">
              <div style="width:64px;height:64px"
                   class="d-flex justify-content-center
                   align-items-center
                   rounded-circle
                   border border-dark">
                <img @error="fallback"
                     class="c-avatar-img" :src="newUploadedStudentPhoto">
              </div>
            </div>
            <c-dropdown-divider/>
            <CRow>
              <CCol class="mt-3">
                <CInput
                    type="text"
                    label="Ad Soyad"
                    v-model="name"
                    description="İsim Ve Soyisim Arasına Bir Boşluk bırakın."
                >
                  <template #prepend-content>
                    <CIcon name="cil-user"/>
                  </template>
                </CInput>
                <CButton class="mb-4" v-if="showNameSaveButton" color="success" @click="updateStName">
                  Kaydet
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                    type="email"
                    autocomplete="email"
                    label="E-Posta Adresi"
                    required
                    v-model="eMail"
                >
                  <template #prepend-content>
                    <CIcon name="cil-envelope-closed"/>
                  </template>
                </CInput>
                <CButton class="mb-4" v-if="showMailSaveButton" color="success" @click="updateMail">
                  Kaydet
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormGroup
                    wrapperClasses="input-group pt-2"
                    description="ex. (555) 555-55-55"
                >
                  <template #prepend-content>
                    <CIcon name="cil-phone"/>
                  </template>
                  <template #label>
                    Telefon Numarası
                  </template>
                    <template #input>
                        <vue-tel-input v-model="phoneNumber" class='form-control'></vue-tel-input>
                    </template>
                </CFormGroup>
                <CButton class="mb-4" v-if="showPhoneNumberSaveButton" color="success" @click="updatePhoneNumber">
                  Kaydet
                </CButton>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol>
                <CInput
                    placeholder="Yeni Şifreyi Giriniz"
                    :type="passwordInputType"
                    label="Hesap Şifresi"
                    required
                    v-model="password">
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked"/>
                  </template>
                  <template #append>
                    <CButton @click="passwordVisibleHandler" color="light">
                      <CIcon name="cil-low-vision"/>
                    </CButton>
                  </template>
                </CInput>
                <c-badge
                    class='col-12'
                    v-if='password !== "" && (password.length < 6 || password.length > 64)'
                    color="danger"
                >Şifre Minimum 6, Maksimum 64 Karakter Uzunluğunda Olmalıdır.
                </c-badge>
                <CButton :disabled='!validateEditStudentPassword' class="mb-4"
                         v-if='showPasswordSaveButton && validateEditStudentPassword' color="success"
                         @click="updatePassword">
                  Kaydet
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

      </CCol>
      <CCol class="col-12 col-md-6">
        <CCard>
          <CCardHeader>
            <strong>Yeni Profil Fotoğrafı Yükleyin</strong>
          </CCardHeader>
          <CCardBody>
            <CRow class="text-center">
              <CCol>
                <croppa
                    v-model="croppa"
                    :width="256"
                    :height="256"
                    :quality="2"
                    :placeholder="'Fotoğraf Yüklemek İçin Tıklayın'"
                    :placeholder-font-size="16"
                >
                </croppa>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="mt-5 d-flex justify-content-center align-items-center">
                <div v-if="croppa.hasImage && croppa.hasImage()">
                  <VueLoadingButton
                      style="background-color: #26994D; font-size: 14px"
                      aria-label="Post message"
                      class="button"
                      @click.native="updateStudentImage"
                      :loading="isLoading"
                      :styled="isStyled"
                  >Fotoğrafı Yükle
                  </VueLoadingButton>
                </div>
              </CCol>
            </CRow>
            <CRow class="justify-content-center mt-1">
              <CCol>
                <CButton v-if="isStudentAvatarApproved" @click="unapproveStudentPicture" block color="danger">Avatarı
                  Reddet
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol col="12" md="4" class="mb-1">
                <CButton v-if="isStudentAvatarApproved" @click="unApproveStudentAccountProfile" block color="danger">
                  Profil Onayını Kaldır
                </CButton>
                <CButton v-else @click="approveStudentAccountProfile" block color="success">Avatarı Onayla</CButton>
              </CCol>
              <CCol col="12" md="4" class="mb-1">
                <CButton @click="deleteStudentAlert" block color="danger">Hesabı Sil</CButton>
              </CCol>
              <CCol col="12" md="4" class="mb-1">
                <CButton v-if="isBanned" @click="unBanStudentAlert(id)" block color="info">Banı Kaldır</CButton>
                <CButton v-else @click="banStudentAlert(id)" block color="danger">Banla</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask'
import {AppointmentAdministration} from "@/services/api-service";
import {StudentAdministration} from "@/services/api-service";
import router from "@/router";
import VueLoadingButton from 'vue-loading-button';

export default {
  name: "ManageStudents",
  components: {
    MaskedInput,
    VueLoadingButton
  },
  mounted() {
    this.loadStudentsAppointment()
    this.loadStudentInfos()
  },
  data() {
    return {
      newUploadedStudentPhoto: null,
      isLoading: false,
      isStyled: true,
      croppa: {},
      isStudentAvatarApproved: false,
      isStudentAvatarApprovedFromServer: false,
      DeleteStudentDueToGdpr: '',
      passwordInputType: 'password',
      isBanned: false,
      unBanStudent: false,
      isPremium: false,
      isPremiumFromServer: '',
      phoneNumber: '',
      phoneNumberFromServer: '',
      password: '',
      passwordFromServer: '',
      eMail: '',
      eMailFromServer: '',
      name: '',
      nameFromServer: '',
      isDataLoading: false,
      toasts: [],
      tableItems: [],
      avatarSet: {
        avatar1X: null,
        avatar2X: null,
        avatar3X: null,
        avatar4X: null
      },
      tableFields: [
        {key: 'appointmentId', label: 'ID'},
        {key: 'teacher', label: 'Planlanan Kişi'},
        {key: 'startDateTime', label: 'Randevu Başlangıcı', _classes: 'text-center'},
        {key: 'endDateTime', label: 'Randevu Bitişi'},
        {key: 'isVideoCallRoomOpened', label: 'Oda Açıldı mı?', _classes: 'text-center'},
        {key: 'isRatingCompleted', label: 'Oylama Tamamlandı mı?'},
        {key: 'isAppointmentDateTimeChangeable', label: 'Randevu Durumu'},
        {key: 'manage', label: 'Kaydı Sil', _classes: 'text-left'},
      ]
    }
  },
  computed: {
    validateStudentName() {
      return (
          (this.password.length >= 6 && this.password.length <= 64)
      )
    },
    validateEditStudentPassword() {
      return (
          (this.password.length >= 6 && this.password.length <= 64)
      )
    },
    getPhoneNumberAsNumber() {
      return this.phoneNumber.replace(/[^0-9]/g, '')
    },
    showNameSaveButton() {
      return this.name !== this.nameFromServer
    },
    showMailSaveButton() {
      return this.eMail !== this.eMailFromServer
    },
    showPasswordSaveButton() {
      return this.password !== this.passwordFromServer
    },
    showPhoneNumberSaveButton() {
      if (this.phoneNumber == null || this.phoneNumber.trim() === '') {
        return false
      }
      return this.phoneNumber.replace(/[^0-9]/g, '') !== this.phoneNumberFromServer.replace(/[^0-9]/g, '')
    },
  },
  methods: {
    fallback(event) {
      event.target.src = "https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png"
    },
    blobConvert() {
      return this.croppa.promisedBlob()
    },
    passwordVisibleHandler() {
      if (this.passwordInputType === "password") {
        this.passwordInputType = "text"
      } else {
        this.passwordInputType = "password"
      }
    },
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    getBadge(status) {
      switch (status) {
        case "Tamamlandı":
          return 'success';
        case "Randevu Başlamadı":
          return 'warning';
        case "İptal Edildi":
          return 'danger';
        default:
          return 'primary';
      }
    },
    banStudentAlert() {
      this.$swal({
        title: 'Öğrenciyi Banlamak istediğinize Emin misiniz? ',
        text: "Yaptığınız İşlemi Daha Sonra Geri Alabilirsiniz.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Banla',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const id = parseInt(this.$route.params['id']);

          try {
            //trelloya unban taski aç (teacherada studentada)
            await StudentAdministration.banStudent(id, Date.now());
            this.isBanned = !this.isBanned
            await this.$swal.fire(
                'Öğrenci Banlandı!',
                'İşlem Başarıyla Gerçekleştirildi!',
                'success'
            )
          } catch (e) {
            console.log(e)
          }
        }
      });
    },
    approveStudentAccountProfile() {
      this.$swal({
        title: 'Avatarı Onaylamak istediğinize Emin misiniz? ',
        text: " ",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Onayla',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const id = parseInt(this.$route.params['id']);
          try {
            await StudentAdministration.approveStudentProfilePicture(id)
            await this.$swal.fire({
              title: 'Avatar Başarıyla Onaylandı!',
              text: 'İşlem Başarıyla Gerçekleştirildi',
              icon: 'success',
              timer: 2000,
            })
            this.isStudentAvatarApproved = !this.isStudentAvatarApproved
          } catch (e) {
            this.toasts.push("İşlem Gerçekleştirilemedi", "Hata")
          }
        }
      });
    },
    unApproveStudentAccountProfile() {
      this.$swal({
        title: 'Avatarın Onayını kaldırmak istediğinize Emin misiniz? ',
        text: " ",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Onayla',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const id = parseInt(this.$route.params['id']);
          try {
            await StudentAdministration.unapproveStudentProfilePicture(id, "unapproved")
            await this.$swal.fire({
              title: 'Avatar Onayı Kaldırıldı!',
              text: 'İşlem Başarıyla Gerçekleştirildi',
              icon: 'success',
              timer: 2000,
            })
            this.isStudentAvatarApproved = !this.isStudentAvatarApproved
          } catch (e) {
          }
        }
      });
    },
    unBanStudentAlert() {
      this.$swal({
        title: 'Öğrencinin Banını Kaldırmak istediğinize Emin misiniz? ',
        text: "Yaptığınız İşlemi Daha Sonra Geri Alabilirsiniz.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const id = parseInt(this.$route.params['id']);

          try {
            //trelloya unban taski aç (teacherada studentada)
            await StudentAdministration.unBanStudent(id, "unbanned")
            this.isBanned = !this.isBanned
            await this.$swal.fire(
                'Öğrencinin Banı Başarıyla Kaldırıldı !',
                'İşlem Başarıyla Gerçekleştirildi!',
                'success'
            )
          } catch (e) {
          }
        }
      });
    },
    deleteStudentAlert() {
      this.$swal({
        title: 'Öğrenciyi Silmek istediğinize Emin misiniz? ',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil',
        cancelButtonText: 'Vazgeç'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const id = parseInt(this.$route.params['id']);
          try {
            await StudentAdministration.deleteStudentAccountDueGDPR(id, Date.now());
            await this.$swal.fire({
              title: 'Öğrenci Silindi!',
              text: 'Öğrenci Başarıyla Silindi!',
              icon: 'success',
              timer: 2000,
              willClose() {
                router.push({path: "/ogrenciler"})
              }
            })
          } catch (e) {
            this.toaster.push("İşlem Gerçekleştirilemedi", "Hata")
          }
        }
      });
    },
    showToast: function (text = 'Değişiklikler Başarıyla Kaydedildi', header = 'Bilgi') {
      this.toasts.push({text, header});
    },
    async loadStudentsAppointment(id) {
      this.isDataLoading = true;
      try {
        const result = await AppointmentAdministration.listAppointmentsOfTheStudent(id);
        this.tableItems = result.data.appointments;
      } catch (e) {
        this.toasts.push("Veri Getirilemedi", "Hata")
      }
      this.isDataLoading = false;
    },
    async loadStudentInfos() {
      this.isDataLoading = true;
      const id = parseInt(this.$route.params['id']);
      try {
        const result = await StudentAdministration.getStudentDetailsForAdministration(id)

        if (!result.data) {
          await router.push({path: "/ogrenciler"})
        }
        this.name = result.data.name
        this.nameFromServer = result.data.name;
        this.eMail = result.data.email;
        this.eMailFromServer = result.data.email;
        this.phoneNumber = result.data.phoneNumber;
        this.phoneNumberFromServer = result.data.phoneNumber;
        this.isPremium = result.data.isPremium
        this.isPremiumFromServer = result.data.isPremium;
        this.isBanned = result.data.isBanned;
        this.isStudentAvatarApproved = result.data.isAvatarApproved
        this.newUploadedStudentPhoto = result.data.avatar1X
        this.avatarSet = {
          avatar1X: result.data.avatar1X,
          avatar2X: result.data.avatar2X,
          avatar3X: result.data.avatar3X,
          avatar4X: result.data.avatar4X,
        }
      } catch (e) {
        this.toasts.push("Veri Getirilemedi", "Hata")
      }
      this.isDataLoading = false;
    },
    async unapproveStudentPicture() {
      this.isDataLoading = true;
      const id = parseInt((this.$route.params['id']))
      try {
        await StudentAdministration.unapproveStudentProfilePicture(id, Date.now())
      } catch (e) {
        this.toasts.push("İşlem Gerçekleştirilemedi", "Hata")
      }
      this.isDataLoading = false;
    },
    async updateStudentImage() {
      this.isLoading = true;
      const id = parseInt(this.$route.params['id'])
      const convertedImage = await this.blobConvert()
      try {
        const result = await StudentAdministration.changeStudentProfilePicture(convertedImage, id, Date.now())
        this.newUploadedStudentPhoto = result.data.createdPhotoInfo.x1_64x64
        this.$toast.success("Öğrenci Avatarı Başarıyla Güncellendi!")
        this.croppa.remove()
      } catch (e) {
        this.toasts.push("İşlem Gerçekleştirilemedi", "Hata")
      }
      this.isLoading = false
    },
    async updatePhoneNumber() {
      const id = parseInt(this.$route.params['id']);
      await StudentAdministration.changeStudentPhoneNumber(id, this.getPhoneNumberAsNumber, Date.now());
      this.phoneNumberFromServer = this.phoneNumber;
      this.$toast.success("Öğrenci Telefon Numarası Başarıyla Güncellendi!")
    },
    async updateStName() {
      const id = parseInt(this.$route.params['id']);
      try {
        await StudentAdministration.changeStudentName(id, this.name, Date.now());
        this.nameFromServer = this.name;
        this.$toast.success("Öğrenci İsmi Başarıyla Güncellendi!")
      } catch (e) {
        if (e.response.data.fields['NewName']) {
          this.$toast.error("İsim ve Soyisim Olmalıdır")
        }
      }
    },
    async updateMail() {
      const id = parseInt(this.$route.params['id']);
      await StudentAdministration.changeStudentEmail(id, this.eMail, Date.now());
      this.eMailFromServer = this.eMail;
      this.$toast.success("Öğrenci Maili Başarıyla Güncellendi!")
    },
    async updatePassword() {
      const id = parseInt(this.$route.params['id']);
      await StudentAdministration.changeStudentPassword(id, Date.now().toString(), this.password);
      this.passwordFromServer = this.password
      this.$toast.success("Öğrenci Şifresi Başarıyla Güncellendi!")
      this.password = ''
    },
  }
}

</script>

